require('core-js/stable')
require('regenerator-runtime/runtime')

const { req, exportDoc } = require('./utils')
const html = require('nanohtml')
const _ = require('morphable')
const css = require('sheetify')
const Centerpieces = require('./components/Centerpieces')
const style = css('./style.sass')

/** Init App **/
if (typeof window !== 'undefined') {
  const state = createStore()
  document.body = html`<body class="${style}">
    ${_(Main)(state)}
  </body>`
}

/** Main Component **/
function Main (state) {
  return html`<main>
    ${state.loading ? html`<div class="pageloader is-active"><span class="title">Loading...</span></div>` : (
      state.error ? html`<div class="has-text-centered">Error! ${JSON.stringify(state.error)}</div>` : (
        Centerpieces(state.centerpieces(), state)
      )
    )}
  </main>`
}

/** App State **/
function createStore () {
  const state = _({
    data: [],
    selected: new Set(),
    error: null,
    loading: true,
    exporting: false,
    getSelected () {
      return Array.from(state.selected).map(state.getCenterpiece)
    },
    getCenterpiece (id) {
      return state.data[state.data.length - 1 - id]
    },
    centerpieces (n) {
      return state.data.slice(0, n)
    },
    load () {
      getCenterpieces(state)
        .then(({ centerpieces = [] }) => {
          state.data = centerpieces
          state.loading = false
        })
        .catch(error => {
          console.log('error', error)
          state.error = error
          state.loading = false
        })
    },
    export () {
      state.exporting = true
      const selected = state.getSelected()
      console.log(selected)
      exportDoc(selected, function () {
        state.exporting = false
      })
    }
  })
  state.load()

  return state
}

/** Helpers **/
function getCenterpieces (state) {
  const res = req(`{
    centerpieces {
      id
      timestamp
      body
      themes
      focuses
      common_commitments
      topics
      expressions
      medium
      authors
      editors
      title_of_piece
      barefoot_given_titles
      title_of_work
      location
      press
      locator
      publication_date
      citation_data
      comments
      related
      submitter_name
      dates_used
    }
  }`)
  console.log('get', res)
  return res || []
}
