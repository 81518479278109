const _ = require('morphable')
const html = require('nanohtml')

module.exports = _((parent, state) => {
  const selected = parent.getSelected().filter(x => typeof x === 'object')
  const { search } = state.search
  return html`<div id="selected" class="card">
    <div class="level">
    	<div class="count">${parent.selected.size} Selected</div>
    	<button class="button is-small is-primary ${parent.exporting ? `is-loading` : ``}" ${parent.selected.size == 0 ? `disabled` : ``} onclick=${parent.export}>Export to Centerpiece</button>
    </div>
    <div class="selections">
	    <table class="table is-fullwidth">
	    ${selected.length > 0 ? selected.map(({ id, authors, title_of_work, title_of_piece }) => html`<tr>
	    <td class="level">
	    	<div>
	    		<div class="has-text-weight-bold"><a href="#" onclick=${e => onclick(e, () => {
	    			state.focuses.selected.clear()
	    			state.commitments.selected.clear()
	    			state.themes.selected.clear()
	    			state.topics.selected.clear()
	    			state.expressions.selected.clear()
	    			search(title_of_piece || title_of_work)
	    		})}>${title_of_piece || title_of_work}</a></div>
	    		<div>${authors}</div>
	    	</div>
	    	<span class="is-small delete" onclick=${() => parent.selected.delete(id)}></span>
	    </td></tr>`) : `Select pieces to export to a centerpiece`}
	    </table>
	  </div>
  </div>`
})

function onclick (e, cb) {
  e.preventDefault()
  cb()
  return false
}
