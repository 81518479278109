const validUrl = require('valid-url')
const { Document, TextRun, Paragraph, Packer, FootnoteReferenceRun, HeadingLevel, AlignmentType } = require('docx')

module.exports = {
  req,
  formatCitation,
  exportDoc
}

function exportDoc (selected = [], done) {
  const footnotes = []
  const children = []

  const paragraphStyles = [{
    id: 'Heading1',
    name: 'Heading 1',
    basedOn: 'Normal',
    next: 'Normal',
    quickFormat: true,
    run: {
      font: 'Avenir',
      size: 36,
      color: '000000'
    },
    paragraph: {
      alignment: AlignmentType.CENTER,
      spacing: { line: 340 }
    }
  }]

  children.push(new Paragraph({
    children: [
      new TextRun({ text: `Centerpiece Working Document`, italics: true })
    ],
    heading: HeadingLevel.HEADING_1
  }))
  children.push(new Paragraph({
    children: [
      new TextRun({ text: `Exported: ${(new Date()).toDateString()}`, italics: true })
    ],
    alignment: AlignmentType.CENTER
  }))
  children.push(new Paragraph(``))
  children.push(new Paragraph({
    children: [
      new TextRun({ text: `Here is a fresh off the vine working document grown from your selected pieces, prepared with Chicago-style citations. You and your co-facilitator(s) may choose to use this document as a jumping off point for your group’s next centerpiece. As you discover and fold in your own source material, be sure to submit to the Centerpiece Explorer for future facilitators to find! Happy centerpiecing!`, italics: true })
    ]
  }))
  children.push(new Paragraph(``))
  children.push(new Paragraph({
    children: [
      new TextRun({ text: `With gratitude, Barefoot Coordinators Present and Past` })
    ],
    alignment: AlignmentType.RIGHT
  }))
  children.push(new Paragraph(``))
  children.push(new Paragraph(``))
  children.push(new Paragraph(`--------------`))
  children.push(new Paragraph(``))

  selected.forEach((data, id) => {
    const citation = formatCitation(data, { includeComments: true })
    const { title_of_piece, barefoot_given_titles, body } = data

    children.push(new Paragraph({ children: [
      new TextRun({
        children: [body, new FootnoteReferenceRun(id + 1)],
        italics: true
      })
    ]}))
    children.push(new Paragraph(``))
    footnotes.push(new Paragraph({
      children: citation.split('*').map((text, i) => new TextRun({ text, italics: i % 2 ? true : false})) // hacky: i'm splitting on * to manually convert *text* to italics. i'm using markdown because i expected to use pandoc for converting to docx. holding off on a decision until later. this works for now.
    }))
    children.push(new Paragraph({
      children: [
        new TextRun({ text: `Author Given Title: ` }),
        new TextRun({ text: `${title_of_piece}\n`, bold: true })
      ]
    }))
    children.push(new Paragraph({
      children: [
        new TextRun({ text: `Barefoot Given Titles: ` }),
        new TextRun({ text: barefoot_given_titles.length > 0 ? barefoot_given_titles.join(', ') : ``, bold: true }),
        new TextRun({ text: barefoot_given_titles.length > 0 ? `` : `None yet!` })
      ]
    }))

    // if (id !== selected.length-1) {
    children.push(new Paragraph(``))
    children.push(new Paragraph(`--------------`))
    children.push(new Paragraph(``))
    // }
  })

  const doc = new Document({
    footnotes,
    styles: {
      paragraphStyles
    }
  })

  doc.addSection({ children })

  Packer.toBlob(doc).then((blob) => {
    saveByteArray('Centerpiece', blob)
    if (done) done()
  })
}

function saveByteArray (name, blob) {
  var link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = name
  link.click()
}

function formatCitation ({ title_of_piece, title_of_work, authors, editors, location, press, publication_date, locator, citation_data, comments }, opts = {}) {
  const { includeComments = false } = opts || {}
  const isURL = validUrl.isUri((press || ``).toString().trim())
  var website = ``
  if (isURL) {
    website = press
    press = ``
  }
  const publisher = location || press || publication_date ? `(${location ? `${location}: ` : ``}${press ? `${press},` : ``} ${publication_date || ``})` : ``
  return `${authors.join(', ')}, ${title_of_piece ? `"${title_of_piece}," ` : ``}${title_of_work ? `*${title_of_work}*, ` : ``}${editors ? `ed. ${editors} ` : ``}${publisher}${locator ? `, ${locator}` : ``}${website ? `, ${website}` : ``}. ${(includeComments && comments) ? `Notes: ${comments.trim()}` : ``}`
}

function req (query, variables) {
  return new Promise((resolve, reject) => fetch('/api/graphql', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    body: JSON.stringify({
      query,
      variables
    })
  })
  .then(res => res.json())
  .then(({ data }) => resolve(data))
  .catch(error => reject(error)))
}
