"use strict";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var onload = require('on-load');

var morph = require('nanomorph');

var util = require('@nx-js/observer-util');

var events = require('events');

var observable = util.observable,
    observe = util.observe,
    unobserve = util.unobserve,
    isObservable = util.isObservable;
var KEY_ATTR = 'data-' + onload.KEY_ID;
var id = 1;

function morphable(view) {
  var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (typeof view !== 'function') return observable(view);

  var _ref = opts || {},
      _ref$reactiveView = _ref.reactiveView,
      reactiveView = _ref$reactiveView === void 0 ? true : _ref$reactiveView,
      _ref$observeListeners = _ref.observeListeners,
      observeListeners = _ref$observeListeners === void 0 ? false : _ref$observeListeners;

  var cache = new WeakMap();
  var reactions = new WeakMap();
  var _int = 0;

  var fn = function fn() {
    var self = this;
    var args = Array.from(arguments);
    var index = isObservable(self) ? self : args[0];
    var rawArgs = args.map(function (state) {
      return morphable.raw(state);
    });
    var rawSelf = isObservable(self) ? morphable.raw(self) : self;
    var listenerArgs = observeListeners ? args : rawArgs;
    var listenerSelf = observeListeners ? self : rawSelf;
    var element;

    if (cache.has(index)) {
      element = cache.get(index);
    } else {
      element = view.apply(rawSelf, rawArgs);

      if (!element.id) {
        if (fn.id) {
          if (_int == 0) {
            element.id = fn.id;
            _int++;
          } else {
            element.id = "".concat(fn.id, "-").concat(_int++);
          }
        } else {
          element.id = id++;
        }
      }
    }

    return onload(element, function (el) {
      if (isObservable(index)) {
        if (reactions.has(index)) return;
        cache.set(index, el);
      }

      var init = false;
      var old_id = el.id;
      var reaction = observe(function () {
        if (!init || reactiveView) {
          fn.emit.apply(fn, ['premorph', el].concat(_toConsumableArray(listenerArgs), [listenerSelf]));
          var update = view.apply(self, args);
          update.id = update.id || old_id;
          update.setAttribute(KEY_ATTR, (cache.get(index) || el).getAttribute(KEY_ATTR));
          morph(el, update);
        }

        if (init) {
          fn.emit.apply(fn, ['morph', el].concat(_toConsumableArray(listenerArgs), [listenerSelf]));
        } else {
          fn.emit.apply(fn, ['load', el].concat(_toConsumableArray(listenerArgs), [listenerSelf]));
          init = true;
        }
      });

      if (isObservable(index)) {
        reactions.set(index, reaction);
      }
    }, function (el) {
      if (document.documentElement.contains(el) || !reactions.has(index)) return;
      fn.emit.apply(fn, ['unload', el].concat(_toConsumableArray(listenerArgs), [listenerSelf]));
      unobserve(reactions.get(index));
      reactions["delete"](index);
    }, id);
  };

  events.call(fn);
  Object.assign(fn, events.prototype);
  return fn;
}

Object.assign(morphable, util);
module.exports = morphable;
