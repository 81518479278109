const html = require('nanohtml')
const _ = require('morphable')

module.exports = _((state, label) => html`<div class="select is-multiple">
	<label class="level">
		<span>${label} (${state.selected.size})</span>
		<span class="is-small delete" onclick=${() => state.selected.clear()}></span>
	</label>
  <select multiple onchange=${e => onchange(e, state)}>
    ${Array.from(state.options).map(option => html`<option value=${option} onmousedown=${onmousedown} ${state.selected.has(option) ? `selected` : ``}>${option}</option>`)}
  </select>
</div>`)

function onmousedown (e) {
	// prevent deselection (allow multiselect without holding down cmd/ctrl)
	e.preventDefault()
	const select = e.target.parentElement
	const scrollTop = select.scrollTop
	e.target.selected = !e.target.selected
	setTimeout(() => select.scrollTop = scrollTop, 0)
	select.focus()

	// manually dispatch change event
	var event = document.createEvent('Event')
	event.initEvent('change', true, true)
	e.target.parentElement.dispatchEvent(event)

	return false
}

function onchange (e, state) {
	e.preventDefault()
	state.selected = new Set(Array.from(e.target.selectedOptions).map(x => x.value))
	return false
}