const { formatCitation } = require('../utils')
const validUrl = require('valid-url')
const html = require('nanohtml')
const raw = require('nanohtml/raw')
const _ = require('morphable')
const marked = require('marked')
marked.setOptions({
  breaks: true
})

const Centerpiece = _(function (data, state, selected) {
  const { id, title_of_piece, title_of_work, authors, body, themes, common_commitments, focuses, topics, expressions, editors, location, locator, press, publication_date, medium, comments, related, citation_data, submitter_name, dates_used, barefoot_given_titles, timestamp } = _.raw(data)

  const map = {
    focuses,
    commitments: common_commitments,
    themes,
    topics,
    expressions
  }

  // naive keyword search
  const str = JSON.stringify(arguments[0] || {}).toLowerCase()
  const keywords = state.search.needle.split(' ')
  const searchMatch = keywords.filter(keyword => str.indexOf(keyword) >= 0).length == keywords.length

  if (body && (!state.populated || (searchMatch && matches(state, map)))) {
    const citation = formatCitation({ title_of_piece, title_of_work, authors, editors, editors, location, locator, press, publication_date, comments, citation_data, dates_used })
    return html`
      <div class="card centerpiece">
        <header class="level">
          <div>
            <input class="is-checkradio" type="checkbox" checked=${selected.has(id)} id=${`piece-${id}`} onchange=${() => {
              if (selected) {
                if (selected.has(id)) {
                  selected.delete(id)
                } else {
                  selected.add(id)
                }
              }
            }}>
              <label for=${`piece-${id}`}>${selected.has(id) ? `Piece selected!` : `Select this piece`}</label><br>
          </div>
        </header>

        <article>
          ${raw(marked.parse(parseBody(body)))}
        </article>

        <footer class="content">

          ${(focuses || []).length > 0 ? html`<h6 class="has-text-weight-semibold">Focus</h6>` : ``}
          <div class="tags">
            ${(focuses || []).map(focus => {
              if (!state.populated) state['focuses'].options.add(focus)
              return html`<span class="tag is-primary is-light">${focus}</span>`
            })}
          </div>

          ${(themes || []).length > 0 ? html`<h6 class="has-text-weight-semibold">Themes</h6>` : ``}
          <div class="tags">
            ${(themes || []).map(theme => {
              if (!state.populated) state['themes'].options.add(theme)
              return html`<span class="tag is-primary is-light">${theme}</span>`
            })}
          </div>

          ${(common_commitments || []).length > 0 ? html`<h6 class="has-text-weight-semibold">Common Commitments</h6>` : ``}
          <div class="tags">
            ${(common_commitments || []).map(commitment => {
              if (!state.populated) state['commitments'].options.add(commitment)
              return html`<span class="tag is-primary is-light">${commitment}</span>`
            })}
          </div>

          ${(topics || []).length > 0 ? html`<h6 class="has-text-weight-semibold">Topics</h6>` : ``}
          <div class="tags">
            ${(topics || []).map(topic => {
              if (!state.populated) state['topics'].options.add(topic)
              return html`<span class="tag is-primary is-light">${topic}</span>`
            })}
          </div>

          ${(expressions || []).length > 0 ? html`<h6 class="has-text-weight-semibold">Expressions</h6>` : ``}
          <div class="tags">
            ${(expressions || []).map(expression => {
              if (!state.populated) state['expressions'].options.add(expression)
              return html`<span class="tag is-primary is-light">${expression}</span>`
            })}
          </div>

          <table class="table is-fullwidth">
            ${shouldShow(title_of_piece) ? html`<tr>
              <th>Author Given Title</th>
              <td>${title_of_piece}</td>
            </tr>` : ``}
            <tr>
              <th>Barefoot Given Titles</th>
              <td>${shouldShow(barefoot_given_titles) ? barefoot_given_titles.join(', ') : `None yet!`}</td>
            </tr>
            ${shouldShow(citation) ? html`<tr>
              <th>Citation</th>
              <td>${raw(marked.parse(citation))}</td>
            </tr>` : ``}
            ${shouldShow(citation_data) ? html`<tr>
              <th>Add'l Citation Data</th>
              <td>${citation_data}</td>
            </tr>` : ``}
            ${shouldShow(medium, 'medium') ? html`<tr>
              <th>Medium</th>
              <td>${medium.join(', ')}</td>
            </tr>` : ``}
            ${shouldShow(submitter_name) ? html`<tr>
              <th>Submitted By</th>
              <td>${submitter_name}</td>
            </tr>` : ``}
            ${shouldShow(timestamp) ? html`<tr>
              <th>Date Submitted</th>
              <td>${timestamp}</td>
            </tr>` : ``}
            ${shouldShow(related) ? html`<tr>
              <th>Pairings</th>
              <td>${related.join(', ')}</td>
            </tr>` : ``}
            ${shouldShow(comments) ? html`<tr>
              <th>Notes</th>
              <td>${comments}</td>
            </tr>` : ``}
            ${shouldShow(dates_used) ? html`<tr>
              <th>Dates Used</th>
              <td>${dates_used.join(', ')}</td>
            </tr>` : ``}
          </table>
        </footer>

      </div>`
  } else {
    // just display none
    return html`<div class="is-hidden"></div>`
  }
})

module.exports = Centerpiece

function shouldShow (data, type) {
  if (typeof data === 'string') {
    data = data.trim()
    return !!data
  } else if (Array.isArray(data)) {
    return data.length > 0
  } else {
    return !!data
  }
}

function matches (state, map) {
  var matches = true

  const keys = Object.keys(state)
  for (var i = keys.length - 1; i >= 0; i--) {
    if (state[keys[i]] && state[keys[i]].selected) {
      const arr = Array.from(state[keys[i]].selected)
      if (arr.length != 0) {
        if (!map[keys[i]] || map[keys[i]].length == 0 || arr.map(x => map[keys[i]].indexOf(x)).indexOf(-1) != -1) {
          matches = false
          break
        }
      }
    }
  }

  return matches
}

function parseBody (body = '') {
  const isURL = validUrl.isUri((body || ``).toString().trim())
  if (!isURL) {
    return body || ''
  } else {
    if (body.indexOf('youtube.com') >= 0) {
      return youtubeEmbedCode(body)
    } else {
      return `<p><a href="${body}">${body}</a></p><iframe sandbox="allow-same-origin allow-scripts allow-popups allow-forms" style="width: 100%; height: 500px;" src="${body}" frameborder="0" allowfullscreen></iframe>`
    }
  }
}

function youtubeEmbedCode (url) {
  return `<iframe width="560" height="315" src="//www.youtube.com/embed/${getId(url)}" frameborder="0" allowfullscreen></iframe>`
}

function getId (url) {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
  const match = url.match(regExp)

  return (match && match[2].length === 11)
      ? match[2]
      : null
}
