const html = require('nanohtml')
const raw = require('nanohtml/raw')
const _ = require('morphable')

const Search = require('./Search')
const Select = require('./Select')
const Selected = require('./Selected')
const Centerpiece = require('./Centerpiece')

const state = _({
  populated: false,
  search: {
    needle: ``,
    search (needle=``) {
      state.search.needle = needle.toLowerCase()
    }
  },
  focuses: {
    options: new Set(),
    selected: new Set()
  },
  commitments: {
    options: new Set(),
    selected: new Set()
  },
  themes: {
    options: new Set(),
    selected: new Set()
  },
  topics: {
    options: new Set(),
    selected: new Set()
  },
  expressions: {
    options: new Set(),
    selected: new Set()
  }
})

const Centerpieces = (centerpieces=[], parent) => {
  const el = html`<main id="centerpieces">
    <header>
      <h1 class="is-size-2 has-text-centered">Barefoot Centerpiece Explorer <img src="/assets/dark.png"></h1>
        <div id="searchbar">
          ${Search(state.search)}
        </div>
        <div id="filters">
          ${Select(state.focuses, 'Focus')}
          ${Select(state.themes, 'Theme')}
          ${Select(state.commitments, 'Common Commitments')}
          ${Select(state.topics, 'Topic')}
          ${Select(state.expressions, 'Expression')}
        </div>
        ${Selected(parent, state)}
    </header>
    ${centerpieces.map(centerpiece => Centerpiece(centerpiece, state, parent.selected)).filter(x => !!x)}
    <footer class="footer">
      <div class="content has-text-centered">
        <p>
          <strong>Barefoot Centerpiece Explorer</strong> by Lucas. Made with ❤.
        </p>
      </div>
    </footer>
  </main>`

  state.populated = true

  return el
}

module.exports = Centerpieces